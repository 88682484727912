<template>
  <div class="orderDetile line inline-block">
    <el-button type="text" size="mini" @click="to">详请</el-button>
    <el-dialog title="详情" :visible.sync="orderDetailDialogVisible" width="850px">
      <div>
        <div class="border-left-2">收货和物流信息</div>
        <table width="100%" border="1" cellspacing="0" cellpadding="0" class="custom-table table-form mt15">
          <colgroup>
            <col width="18%" />
            <col width="32%" />
            <col width="18%" />
            <col width="32%" />
          </colgroup>
          <tr>
            <td>收货人:</td>
            <td>{{ externalOrderLogistics.consigneeName }}</td>
            <td>收货地址:</td>
            <td>{{ externalOrderLogistics.consigneeDetailAddress }}</td>
          </tr>
          <tr>
            <td>收货人电话:</td>
            <td>{{ externalOrderLogistics.consigneeContactPhone }}</td>
            <td>收货人手机号码:</td>
            <td>{{ externalOrderLogistics.consigneeContactMobilePhone }}</td>
          </tr>
          <tr>
            <td>物流方式:</td>
            <td>{{ expressName(data) }}</td>
            <td>运单号:</td>
            <td>{{ data.expressWaybillCode }}</td>
          </tr>
        </table>
      </div>
      <div class="mt20">
        <div class="border-left-2">
          <span class="mr15">产品信息</span>
          <span class="mr15">订单号: {{ data.orderCode }}</span>
          <span class="mr15">导入时间: {{ data.orderTime }}</span>
        </div>
        <customTable :data="data.orderItemDTOList" :option="orderOption">
          <template #productLabelBarcode="{ row: { productLabelBarcode } }">{{
            productLabelBarcode == null ? '--' : productLabelBarcode
          }}</template>
          <template #productCnName="{ row }">
            <div class="cell-img-text">
              <baseImage :src="row.productShowCover" size="50"></baseImage>
              <div>{{ row.productCnName }}</div>
            </div>
          </template>
          <template #internalUnitPrice="{ row }">
            {{ row.internalUnitPrice || '--' }}
          </template>
          <template #unitNetWeight="{ row }">
            {{ row.unitNetWeight || '--' }}
          </template>
          <template #productSizeName="{ row }">
            {{ row.productSizeName == '0' ? '--' : row.productStructName }}
          </template>
          <template #knifeLayoutPath="{ row }">
            <div class="cell-img">
              <template v-if="row.productType === PRODUCT_TYPE_COMBINATION">
                <baseImage
                  v-for="item in row.orderItemChildren"
                  :key="item.id"
                  :src="aLiCompressPicByUrl(row.knifeLayoutPath, 50, true)"
                  size="50"
                ></baseImage>
              </template>
              <baseImage v-else :src="aLiCompressPicByUrl(row.knifeLayoutPath, 50, true)" size="50"></baseImage>
            </div>
          </template>
          <template #productStructName="{ row: { productStructName } }">
            {{ productStructName == '' ? '--' : productStructName }}
          </template>
        </customTable>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import customTable from '@/views/components/customTable'
import { orderOption } from './cols'
import { PRODUCT_TYPE_COMBINATION } from '@/utils/constant'

export default {
  components: {
    customTable
  },
  data() {
    return {
      PRODUCT_TYPE_COMBINATION,
      orderDetailDialogVisible: false,
      orderOption
    }
  },
  props: {
    visible: {
      type: Boolean
    },
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  computed: {
    externalOrderLogistics({ data }) {
      return $GET(data, 'externalOrderLogistics', data)
    }
  },

  watch: {
    data: {
      handler() {
        this.page = {
          pageIndex: 1,
          pageSize: 4,
          total: this.data.orderItemDTOList.length
        }
      },
      deep: true
    },
    visible: {
      handler(n) {
        this.orderDetailDialogVisible = n
      },
      immediate: true
    },
    orderDetailDialogVisible(n) {
      this.$emit('update:visible', n)
    }
  },
  methods: {
    to() {
      this.orderDetailDialogVisible = true
    },
    pageChange(e) {
      this.page.pageIndex = e
    },
    sizeChange(e) {
      this.page.pageIndex = 1
      this.page.pageSize = e
    },
    expressName(scoped) {
      if (scoped.orderType == 3) {
        return this.logistics_service_name(scoped.expressThird)
      }
      return scoped.freightPaymentType ? scoped.expressShippingMethodCnName || '暂无' : ''
    },
    logistics_service_name(expressThird) {
      try {
        return JSON.parse(expressThird)?.logistics_service_name
      } catch (error) {
        return '暂无'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.orderDetile {
  display: inline-block;
  text-align: left;
}
::v-deep {
  .el-dialog {
    margin-top: 10vh;
  }
}

// 订单详情 - 弹窗
.custom-table {
  border-collapse: collapse;
  td {
    padding: 8px;
  }
  tr,
  td {
    border-color: #ebeef5;
    border-right: 1px solid #ebeef5;
  }
}
.table-form {
  td:nth-child(2n-1) {
    background-color: #f7f8fa;
    font-weight: bold;
  }
}

.border-left-2 {
  padding-left: 5px;
  border-left: 2px solid $color-primary;
}
</style>
