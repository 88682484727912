var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orderDetile line inline-block"},[_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":_vm.to}},[_vm._v("详请")]),_c('el-dialog',{attrs:{"title":"详情","visible":_vm.orderDetailDialogVisible,"width":"850px"},on:{"update:visible":function($event){_vm.orderDetailDialogVisible=$event}}},[_c('div',[_c('div',{staticClass:"border-left-2"},[_vm._v("收货和物流信息")]),_c('table',{staticClass:"custom-table table-form mt15",attrs:{"width":"100%","border":"1","cellspacing":"0","cellpadding":"0"}},[_c('colgroup',[_c('col',{attrs:{"width":"18%"}}),_c('col',{attrs:{"width":"32%"}}),_c('col',{attrs:{"width":"18%"}}),_c('col',{attrs:{"width":"32%"}})]),_c('tr',[_c('td',[_vm._v("收货人:")]),_c('td',[_vm._v(_vm._s(_vm.externalOrderLogistics.consigneeName))]),_c('td',[_vm._v("收货地址:")]),_c('td',[_vm._v(_vm._s(_vm.externalOrderLogistics.consigneeDetailAddress))])]),_c('tr',[_c('td',[_vm._v("收货人电话:")]),_c('td',[_vm._v(_vm._s(_vm.externalOrderLogistics.consigneeContactPhone))]),_c('td',[_vm._v("收货人手机号码:")]),_c('td',[_vm._v(_vm._s(_vm.externalOrderLogistics.consigneeContactMobilePhone))])]),_c('tr',[_c('td',[_vm._v("物流方式:")]),_c('td',[_vm._v(_vm._s(_vm.expressName(_vm.data)))]),_c('td',[_vm._v("运单号:")]),_c('td',[_vm._v(_vm._s(_vm.data.expressWaybillCode))])])])]),_c('div',{staticClass:"mt20"},[_c('div',{staticClass:"border-left-2"},[_c('span',{staticClass:"mr15"},[_vm._v("产品信息")]),_c('span',{staticClass:"mr15"},[_vm._v("订单号: "+_vm._s(_vm.data.orderCode))]),_c('span',{staticClass:"mr15"},[_vm._v("导入时间: "+_vm._s(_vm.data.orderTime))])]),_c('customTable',{attrs:{"data":_vm.data.orderItemDTOList,"option":_vm.orderOption},scopedSlots:_vm._u([{key:"productLabelBarcode",fn:function(ref){
var productLabelBarcode = ref.row.productLabelBarcode;
return [_vm._v(_vm._s(productLabelBarcode == null ? '--' : productLabelBarcode))]}},{key:"productCnName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-img-text"},[_c('baseImage',{attrs:{"src":row.productShowCover,"size":"50"}}),_c('div',[_vm._v(_vm._s(row.productCnName))])],1)]}},{key:"internalUnitPrice",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.internalUnitPrice || '--')+" ")]}},{key:"unitNetWeight",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.unitNetWeight || '--')+" ")]}},{key:"productSizeName",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.productSizeName == '0' ? '--' : row.productStructName)+" ")]}},{key:"knifeLayoutPath",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-img"},[(row.productType === _vm.PRODUCT_TYPE_COMBINATION)?_vm._l((row.orderItemChildren),function(item){return _c('baseImage',{key:item.id,attrs:{"src":_vm.aLiCompressPicByUrl(row.knifeLayoutPath, 50, true),"size":"50"}})}):_c('baseImage',{attrs:{"src":_vm.aLiCompressPicByUrl(row.knifeLayoutPath, 50, true),"size":"50"}})],2)]}},{key:"productStructName",fn:function(ref){
var productStructName = ref.row.productStructName;
return [_vm._v(" "+_vm._s(productStructName == '' ? '--' : productStructName)+" ")]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }